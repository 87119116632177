<template>
  <div :class="dark? 'theme--dark v-toolbar v-sheet v-sheet--tile' :''">
    <v-img
      v-if="group && group.img"
      :src="group.img"
      :class="`white--text align-end ${group.logo ? 'logo-container': ''} ${$vuetify.breakpoint.xs?'container-xs':''} ${$vuetify.breakpoint.sm?'container-sm':''}`"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      :height="$vuetify.breakpoint.xs?'150px':'300px'"
    >
      <span v-if="group.logo" :class="`event-logo pa-3 v-card elevation-7 v-sheet theme--light`">
        <img :alt="`Logo of ${group.name}`" :src="group.logo" />
      </span>
      <v-card-title class="display-2" v-text="title || group.name"></v-card-title>
    </v-img>
    <v-card-title v-else class="display-2" v-text="title || group.name"></v-card-title>
  </div>
</template>

<script>
export default {
  name: "GroupHeader",
  components: {
  },
  props: {
    group: Object,
    title: String,
    dark: Boolean,
  },
};
</script>

<style lang="scss">
  .logo-container { overflow: inherit !important; margin-bottom: 42px;}
  .logo-container .v-responsive__sizer { padding-bottom: 0!important;}
  .logo-container .display-2 { display: inline-block; margin-left: 250px;}
  .logo-container.container-xs .display-2 { margin-left: 150px;}
  .logo-container.container-sm .display-2 { margin-left: 200px;}
  .event-logo { background-color: white; display: inline-block !important; left: 16px; position: absolute !important; bottom: -32px; }
  .event-logo img { max-width: 200px; }
  .container-xs .event-logo img { max-width: 100px; }
  .container-sm .event-logo img { max-width: 150px; }
</style>